import { getStoredUserToken } from './auth.service';

export type FetchParams = {
  urlParamsObject?: any;
  headers?: any;
  options?: any;
};

export function getStrapiURL(path = '') {
  return `${process.env.NEXT_PUBLIC_STRAPI_API_URL || 'http://localhost:1337/api'
    }${path}`;
}

export async function fetchAPI<T>(
  fullPath: string,
  path: string,
  params: FetchParams
): Promise<T> {
  // Merge default and user options
  const mergedOptions: any = {
    headers: params?.headers,
    ...params?.options,
  };

  // Build request URL
  let requestUrl = fullPath;

  if (params.urlParamsObject) {
    const queryString = new URLSearchParams(params.urlParamsObject);
    requestUrl = `${fullPath}?${queryString.toString()}`;
  }

  // Trigger API call
  const response = await fetch(requestUrl, mergedOptions);

  // Handle response
  if (!response.ok) {
    const errorData = {
      status: response.status,
      statusText: response.statusText,
      url: response.url,
      headers: response.headers,
      requestUrl,
      mergedOptions,
    };
    throw { errorData };
  } else {
    return await response.json();
  }
}

export async function fetchStrapi<T>(
  path: string,
  urlParamsObject?: any,
  apiKey?: string
): Promise<T> {
  const token = apiKey || getStoredUserToken();
  const params: FetchParams = {
    urlParamsObject,
    headers: {
      Accept: 'application/json',
    },
    options: {
      method: 'GET'
    },
  };

  if (!!token) {
    params.headers['Authorization'] = `Bearer ${token}`;
  }

  return await fetchAPI(getStrapiURL(path), path, params);
}

export async function postStrapi<T>(path: string, body: T): Promise<T> {
  const params: FetchParams = {
    headers: {
      'Content-Type': 'application/json',
    },
    options: {
      method: 'POST',
      body: JSON.stringify(body),
    },
  };

  const token = getStoredUserToken();

  if (token) {
    params.headers['Authorization'] = `Bearer ${token}`;
  }

  return await fetchAPI(getStrapiURL(path), path, params);
}

export async function uploadStrapi(path: string, formData: FormData): Promise<any> {
  const token = getStoredUserToken();
  const upload = await fetch(getStrapiURL(path), {
    method: 'POST',
    body: formData,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });

  return await upload.json();
}

export async function putStrapi<T>(path: string, body: T): Promise<T> {
  const params: FetchParams = {
    headers: {
      'Content-Type': 'application/json',
    },
    options: {
      method: 'PUT',
      body: JSON.stringify(body),
    },
  };

  const token = getStoredUserToken();

  if (token) {
    params.headers['Authorization'] = `Bearer ${token}`;
  }

  return await fetchAPI(getStrapiURL(path), path, params);
}

export async function deleteStrapi<T>(path: string): Promise<T> {
  const params: FetchParams = {
    headers: {
      'Content-Type': 'application/json',
    },
    options: {
      method: 'DELETE'
    },
  };

  const token = getStoredUserToken();

  if (token) {
    params.headers['Authorization'] = `Bearer ${token}`;
  }

  return await fetchAPI(getStrapiURL(path), path, params);
}

export async function logInStrapi(
  email: string,
  password: string
): Promise<any> {
  const params: FetchParams = {
    headers: {
      'Content-Type': 'application/json',
    },
    options: {
      method: 'POST',
      body: JSON.stringify({ identifier: email, password }),
    },
  };

  return await fetchAPI(getStrapiURL('/auth/local'), '/auth/local', params);
}

/** Next JS API */

export async function logInAPI(email: string, password: string): Promise<any> {
  const params: FetchParams = {
    headers: {
      'Content-Type': 'application/json',
    },
    options: {
      method: 'POST',
      body: JSON.stringify({ email, password }),
    },
  };

  return await fetchAPI('/api/login', '/api/login', params);
}
