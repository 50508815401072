// https://stackblitz.com/edit/github-bizsur-azd1ro?file=src%2Fpages%2Findex.tsx
// https://stackoverflow.com/questions/73761243/you-are-using-legacy-implementaion-please-update-your-code-use-createwrapper

import { configureStore } from '@reduxjs/toolkit';
import appReducer from './slices/appSlice';
import authReducer from './slices/authSlice';
import currentOrderReducer from './slices/currentOrderSlice';

export const store = configureStore({
  reducer: {
    app: appReducer,
    auth: authReducer,
    currentOrder: currentOrderReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
