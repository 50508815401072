const environment = process.env.NEXT_PUBLIC_ENVIRONMENT || 'development';

export function logInfo(params: any[], source?: string, tabular = false): void {
  if (environment === 'development') {
    if (tabular) {
      console.table(
        ...params
      );
    } else {
      console.log(
        new Date().toUTCString() + ' >> source',
        source,
        ' >> ',
        ...params
      );
    }
  }
}

export function logError(params: any[], source?: string): void {
  console.error(
    new Date().toUTCString() + ' >> source',
    source,
    ' >> ',
    ...params
  );
}
