import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '..';
import { IContactMethod, ICookiesPopup, ICulture, ICurrency, IEntitiesStatus, ILanguage, IMainFooter, IMainNavBar } from '../../models/strapi.models';

export interface IAppPageLocalization {
  id: number;
  locale: string;
}
export interface IAppPage {
  source: string;
  destination: string;
  language: string;
  original?: string;
  locale?: boolean | undefined;
  localizations?: IAppPageLocalization[];
  postId?: number
}

type AppState = {
  isLoading: boolean;
  languages: ILanguage[];
  culture: ICulture | undefined;
  currencies: ICurrency[];
  currentLocale: string | undefined;
  currentLanguage: ILanguage | undefined;
  currentCurrency: ICurrency | undefined;
  mainNavBar: IMainNavBar | undefined;
  mainFooter: IMainFooter | undefined;
  contactMethods: IContactMethod[];
  cookiesPopup: ICookiesPopup | undefined;
  cookiesAcceptance: { accepted: boolean | undefined };
  pages: IAppPage[] | undefined;
  entitiesStatus: IEntitiesStatus | undefined;
  pendingOrdersCount: number;
};

const initialState: AppState = {
  isLoading: false,
  languages: [],
  culture: undefined,
  currencies: [],
  currentLocale: undefined,
  currentLanguage: undefined,
  currentCurrency: undefined,
  mainNavBar: undefined,
  mainFooter: undefined,
  contactMethods: [],
  cookiesPopup: undefined,
  cookiesAcceptance: { accepted: undefined },
  pages: [],
  entitiesStatus: undefined,
  pendingOrdersCount: 0
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      if (state.isLoading !== action.payload) {
        state.isLoading = action.payload;
      }
    },
    setLanguages: (state, action: PayloadAction<ILanguage[]>) => {
      state.languages = action.payload;
    },
    setCulture: (state, action: PayloadAction<ICulture>) => {
      state.culture = action.payload;
    },
    setCurrentLanguage: (state, action: PayloadAction<ILanguage>) => {
      state.currentLanguage = action.payload;
    },
    setCurrentLocale: (state, action: PayloadAction<string>) => {
      state.currentLocale = action.payload;
    },
    setCurrencies: (state, action: PayloadAction<ICurrency[]>) => {
      state.currencies = action.payload;
    },
    setCurrentCurrency: (state, action: PayloadAction<ICurrency>) => {
      state.currentCurrency = action.payload;
    },
    setMainNavBar: (state, action: PayloadAction<IMainNavBar>) => {
      state.mainNavBar = action.payload;
    },
    setMainFooter: (state, action: PayloadAction<IMainFooter>) => {
      state.mainFooter = action.payload;
    },
    setContactMethods: (state, action: PayloadAction<IContactMethod[]>) => {
      state.contactMethods = action.payload;
    },
    setCookiesPopup: (state, action: PayloadAction<ICookiesPopup>) => {
      state.cookiesPopup = action.payload;
    },
    setCookiesAcceptance: (state, action: PayloadAction<boolean | undefined>) => {
      state.cookiesAcceptance.accepted = action.payload;
    },
    setPages: (state, action: PayloadAction<any[]>) => {
      state.pages = action.payload;
    },
    setEntitiesStatus: (state, action: PayloadAction<IEntitiesStatus>) => {
      state.entitiesStatus = action.payload;
    },
    setPendingOrdersCount: (state, action: PayloadAction<number>) => {
      state.pendingOrdersCount = action.payload;
    }
  },
});

export const {
  setIsLoading,
  setLanguages,
  setCulture,
  setCurrencies,
  setCurrentLanguage,
  setCurrentLocale,
  setCurrentCurrency,
  setMainNavBar,
  setMainFooter,
  setContactMethods,
  setCookiesPopup,
  setCookiesAcceptance,
  setPages,
  setEntitiesStatus,
  setPendingOrdersCount
} = appSlice.actions;
export const getApp = (state: RootState) => state.app;
export default appSlice.reducer;
