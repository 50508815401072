import { GoogleTagManager } from '@next/third-parties/google';
import { NextPage } from 'next';
import { AppProps } from 'next/app';
import Script from 'next/script';
import { ReactElement, ReactNode } from 'react';
import { Provider } from 'react-redux';
import { store } from '../store';
import '../styles/globals.scss';

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement, pageProps?: any) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const getLayout = Component.getLayout ?? ((page) => page);
  const canLoadThirdParties = process.env.NEXT_PUBLIC_ENVIRONMENT !== 'development';
  const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID as string;

  return (
    <Provider store={store}>
      {getLayout(<Component {...pageProps} />, pageProps)}
      {canLoadThirdParties && <GoogleTagManager gtmId={GTM_ID} />}
      {/* ZenDesk chat */}
      {canLoadThirdParties && <Script strategy="lazyOnload" id="ze-snippet" src="https://static.zdassets.com/ekr/snippet.js?key=49e710b1-6324-464e-b276-6bfd27e3ea56" />}
    </Provider>
  );
}

export default MyApp;
