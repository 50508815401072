import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '..';
import { IMe } from '../../models/strapi.models';

type AuthState = {
  user: IMe | undefined;
  isAuthenticated: boolean;
};

const initialState: AuthState = {
  user: undefined,
  isAuthenticated: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<IMe | undefined>) => {
      state.user = action.payload;
    },
    setIsAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
    },
  },
});

export const { setUser, setIsAuthenticated } = authSlice.actions;
export const getAuth = (state: RootState) => state.auth;
export default authSlice.reducer;
