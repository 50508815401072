import { IMe } from '../models/strapi.models';
import { fetchStrapi, logInAPI } from './api.service';
import { logError } from './log.service';

const jwtCookieName = process.env.NEXT_PUBLIC_JWT_COOKIE_NAME || 'jwt';

export function getStoredUserToken(): string | undefined {
  if (typeof window !== 'undefined') {
    const cookies = document.cookie.split('; ');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].split('=');
      if (cookie[0] === jwtCookieName) {
        return cookie[1];
      }
    }
  }
  return undefined;
}

export function removeUserToken(): void {
  if (typeof window !== 'undefined') {
    document.cookie = jwtCookieName + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  }
}

export async function logIn(
  email: string,
  password: string
): Promise<any | undefined> {
  try {
    const me = await logInAPI(email, password);
    return me;
  } catch (error) {
    logError([error], 'Auth service → logIn');
    throw error;
  }
}

export function logOut(): void {
  removeUserToken();
}

export async function getMe(): Promise<IMe | undefined> {
  try {
    const me = await fetchStrapi<IMe>('/users-info/me');
    return me;
  } catch (error) {
    logError([error], 'Auth service → getMe');
    return undefined;
  }
}
