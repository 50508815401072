import { IApplicationClassPrice, ICountryPrice, ICurrency, ISimpleTranslation, ServiceType } from '../../models/strapi.models';
import { generateRandomId } from '../../shared/useUtils';

export interface IAppOrderCountry {
  id: number;
  name: string;
  code: string;
  dividesByClass?: boolean;
  searchClassPrices?: IApplicationClassPrice[];
  applicationClassPrices?: IApplicationClassPrice[];
  finalFees?: IApplicationClassPrice[];
}

export interface IAppOrderSubClass {
  subClassId: number;
  description?: ISimpleTranslation;
}

export interface IAppOrderClass {
  id: number;
  name: string;
  code: number;
  description?: string;
  subClasses?: IAppOrderSubClass[];
  isCollapsed?: boolean;
}

export interface IAppOrder {
  id: string;
  type?: ServiceType;
  trademarkCountries: IAppOrderCountry[];
  trademarkName: string;
  trademarkLogo: string; // Stores DatURL instead of Blob due to Redux serialization capabilities 
  trademarkClasses: IAppOrderClass[];
  userId?: number;
  addressId?: number;
  billingAddressId?: number;
  strapiOrderId?: number;
  totalPrice?: number;
  currency?: ICurrency;
  prices?: ICountryPrice[];
}

export class AppOrder implements IAppOrder {
  public id: string;
  public type?: ServiceType;
  public trademarkCountries: IAppOrderCountry[];
  public trademarkName: string;
  public trademarkLogo: string; // Stores DatURL instead of Blob due to Redux serialization capabilities 
  public trademarkClasses: IAppOrderClass[];
  public userId?: number;
  public addressId?: number;
  public billingAddressId?: number;
  public fullName?: string;
  public taxId?: string;
  public strapiOrderId?: number;
  public totalPrice?: number;
  public currency?: ICurrency | undefined;

  constructor(type: ServiceType) {
    this.id = generateRandomId();
    this.type = type;
    this.trademarkCountries = [];
    this.trademarkName = '';
    this.trademarkLogo = '';
    this.trademarkClasses = [];
  }
}
