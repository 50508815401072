import { IApplicationClassPrice, ISimpleTranslation } from './strapi.models';

export type CountryCode = 'AC' | 'AD' | 'AE' | 'AF' | 'AG' | 'AI' | 'AL' | 'AM' | 'AO' | 'AR' | 'AS' | 'AT' | 'AU' | 'AW' | 'AX' | 'AZ' | 'BA' | 'BB' | 'BD' | 'BE' | 'BF' | 'BG' | 'BH' | 'BI' | 'BJ' | 'BL' | 'BM' | 'BN' | 'BO' | 'BQ' | 'BR' | 'BS' | 'BT' | 'BW' | 'BY' | 'BZ' | 'CA' | 'CC' | 'CD' | 'CF' | 'CG' | 'CH' | 'CI' | 'CK' | 'CL' | 'CM' | 'CN' | 'CO' | 'CR' | 'CU' | 'CV' | 'CW' | 'CX' | 'CY' | 'CZ' | 'DE' | 'DJ' | 'DK' | 'DM' | 'DO' | 'DZ' | 'EC' | 'EE' | 'EG' | 'EH' | 'ER' | 'ES' | 'ET' | 'FI' | 'FJ' | 'FK' | 'FM' | 'FO' | 'FR' | 'GA' | 'GB' | 'GD' | 'GE' | 'GF' | 'GG' | 'GH' | 'GI' | 'GL' | 'GM' | 'GN' | 'GP' | 'GQ' | 'GR' | 'GT' | 'GU' | 'GW' | 'GY' | 'HK' | 'HN' | 'HR' | 'HT' | 'HU' | 'ID' | 'IE' | 'IL' | 'IM' | 'IN' | 'IO' | 'IQ' | 'IR' | 'IS' | 'IT' | 'JE' | 'JM' | 'JO' | 'JP' | 'KE' | 'KG' | 'KH' | 'KI' | 'KM' | 'KN' | 'KP' | 'KR' | 'KW' | 'KY' | 'KZ' | 'LA' | 'LB' | 'LC' | 'LI' | 'LK' | 'LR' | 'LS' | 'LT' | 'LU' | 'LV' | 'LY' | 'MA' | 'MC' | 'MD' | 'ME' | 'MF' | 'MG' | 'MH' | 'MK' | 'ML' | 'MM' | 'MN' | 'MO' | 'MP' | 'MQ' | 'MR' | 'MS' | 'MT' | 'MU' | 'MV' | 'MW' | 'MX' | 'MY' | 'MZ' | 'NA' | 'NC' | 'NE' | 'NF' | 'NG' | 'NI' | 'NL' | 'NO' | 'NP' | 'NR' | 'NU' | 'NZ' | 'OM' | 'PA' | 'PE' | 'PF' | 'PG' | 'PH' | 'PK' | 'PL' | 'PM' | 'PR' | 'PS' | 'PT' | 'PW' | 'PY' | 'QA' | 'RE' | 'RO' | 'RS' | 'RU' | 'RW' | 'SA' | 'SB' | 'SC' | 'SD' | 'SE' | 'SG' | 'SH' | 'SI' | 'SJ' | 'SK' | 'SL' | 'SM' | 'SN' | 'SO' | 'SR' | 'SS' | 'ST' | 'SV' | 'SX' | 'SY' | 'SZ' | 'TA' | 'TC' | 'TD' | 'TG' | 'TH' | 'TJ' | 'TK' | 'TL' | 'TM' | 'TN' | 'TO' | 'TR' | 'TT' | 'TV' | 'TW' | 'TZ' | 'UA' | 'UG' | 'US' | 'UY' | 'UZ' | 'VA' | 'VC' | 'VE' | 'VG' | 'VI' | 'VN' | 'VU' | 'WF' | 'WS' | 'XK' | 'YE' | 'YT' | 'ZA' | 'ZM' | 'ZW';
export const COUNTRY_CODES: CountryCode[] = [
  'AC',
  'AD',
  'AE',
  'AF',
  'AG',
  'AI',
  'AL',
  'AM',
  'AO',
  'AR',
  'AS',
  'AT',
  'AU',
  'AW',
  'AX',
  'AZ',
  'BA',
  'BB',
  'BD',
  'BE',
  'BF',
  'BG',
  'BH',
  'BI',
  'BJ',
  'BL',
  'BM',
  'BN',
  'BO',
  'BQ',
  'BR',
  'BS',
  'BT',
  'BW',
  'BY',
  'BZ',
  'CA',
  'CC',
  'CD',
  'CF',
  'CG',
  'CH',
  'CI',
  'CK',
  'CL',
  'CM',
  'CN',
  'CO',
  'CR',
  'CU',
  'CV',
  'CW',
  'CX',
  'CY',
  'CZ',
  'DE',
  'DJ',
  'DK',
  'DM',
  'DO',
  'DZ',
  'EC',
  'EE',
  'EG',
  'EH',
  'ER',
  'ES',
  'ET',
  'FI',
  'FJ',
  'FK',
  'FM',
  'FO',
  'FR',
  'GA',
  'GB',
  'GD',
  'GE',
  'GF',
  'GG',
  'GH',
  'GI',
  'GL',
  'GM',
  'GN',
  'GP',
  'GQ',
  'GR',
  'GT',
  'GU',
  'GW',
  'GY',
  'HK',
  'HN',
  'HR',
  'HT',
  'HU',
  'ID',
  'IE',
  'IL',
  'IM',
  'IN',
  'IO',
  'IQ',
  'IR',
  'IS',
  'IT',
  'JE',
  'JM',
  'JO',
  'JP',
  'KE',
  'KG',
  'KH',
  'KI',
  'KM',
  'KN',
  'KP',
  'KR',
  'KW',
  'KY',
  'KZ',
  'LA',
];

export const countryCallingCodes = {
  'AF': '93',
  'AL': '355',
  'DZ': '213',
  'AS': '1',
  'AD': '376',
  'AO': '244',
  'AI': '1',
  'AQ': '672',
  'AG': '1',
  'AR': '54',
  'AM': '374',
  'AW': '297',
  'AU': '61',
  'AT': '43',
  'AZ': '994',
  'BS': '1',
  'BH': '973',
  'BD': '880',
  'BB': '1',
  'BY': '375',
  'BE': '32',
  'BZ': '501',
  'BJ': '229',
  'BM': '1',
  'BT': '975',
  'BO': '591',
  'BA': '387',
  'BW': '267',
  'BR': '55',
  'IO': '246',
  'BN': '673',
  'BG': '359',
  'BF': '226',
  'BI': '257',
  'CV': '238',
  'KH': '855',
  'CM': '237',
  'CA': '1',
  'KY': '1',
  'CF': '236',
  'TD': '235',
  'CL': '56',
  'CN': '86',
  'CO': '57',
  'KM': '269',
  'CD': '243',
  'CG': '242',
  'CK': '682',
  'CR': '506',
  'HR': '385',
  'CU': '53',
  'CY': '357',
  'CZ': '420',
  'DK': '45',
  'DJ': '253',
  'DM': '1',
  'DO': '1',
  'EC': '593',
  'EG': '20',
  'SV': '503',
  'GQ': '240',
  'ER': '291',
  'EE': '372',
  'ET': '251',
  'FJ': '679',
  'FI': '358',
  'FR': '33',
  'GA': '241',
  'GM': '220',
  'GE': '995',
  'DE': '49',
  'GH': '233',
  'GI': '350',
  'GR': '30',
  'GL': '299',
  'GD': '1',
  'GU': '1',
  'GT': '502',
  'GN': '224',
  'GW': '245',
  'GY': '592',
  'HT': '509',
  'HN': '504',
  'HK': '852',
  'HU': '36',
  'IS': '354',
  'IN': '91',
  'ID': '62',
  'IR': '98',
  'IQ': '964',
  'IE': '353',
  'IL': '972',
  'IT': '39',
  'CI': '225',
  'JM': '1',
  'JP': '81',
  'JO': '962',
  'KZ': '7',
  'KE': '254',
  'KI': '686',
  'KP': '850',
  'KR': '82',
  'KW': '965',
  'KG': '996',
  'LA': '856',
  'LV': '371',
  'LB': '961',
  'LS': '266',
  'LR': '231',
  'LY': '218',
  'LI': '423',
  'LT': '370',
  'LU': '352',
  'MO': '853',
  'MG': '261',
  'MW': '265',
  'MY': '60',
  'MV': '960',
  'ML': '223',
  'MT': '356',
  'MH': '692',
  'MR': '222',
  'MU': '230',
  'MX': '52',
  'FM': '691',
  'MD': '373',
  'MC': '377',
  'MN': '976',
  'ME': '382',
  'MA': '212',
  'MZ': '258',
  'MM': '95',
  'NA': '264',
  'NR': '674',
  'NP': '977',
  'NL': '31',
  'NZ': '64',
  'NI': '505',
  'NE': '227',
  'NG': '234',
  'NU': '683',
  'MK': '389',
  'NO': '47',
  'OM': '968',
  'PK': '92',
  'PW': '680',
  'PS': '970',
  'PA': '507',
  'PG': '675',
  'PY': '595',
  'PE': '51',
  'PH': '63',
  'PL': '48',
  'PT': '351',
  'PR': '1',
  'QA': '974',
  'RO': '40',
  'RU': '7',
  'RW': '250',
  'KN': '1',
  'LC': '1',
  'VC': '1',
  'WS': '685',
  'SM': '378',
  'ST': '239',
  'SA': '966',
  'SN': '221',
  'RS': '381',
  'SC': '248',
  'SL': '232',
  'SG': '65',
  'SK': '421',
  'SI': '386',
  'SB': '677',
  'SO': '252',
  'ZA': '27',
  'SS': '211',
  'ES': '34',
  'LK': '94',
  'SD': '249',
  'SR': '597',
  'SZ': '268',
  'SE': '46',
  'CH': '41',
  'SY': '963',
  'TW': '886',
  'TJ': '992',
  'TZ': '255',
  'TH': '66',
  'TL': '670',
  'TG': '228',
  'TO': '676',
  'TT': '1',
  'TN': '216',
  'TR': '90',
  'TM': '993',
  'TV': '688',
  'UG': '256',
  'UA': '380',
  'AE': '971',
  'GB': '44',
  'US': '1',
  'UY': '598',
  'UZ': '998',
  'VU': '678',
  'VA': '39',
  'VE': '58',
  'VN': '84',
  'YE': '967',
  'ZM': '260',
  'ZW': '263',
  'JE': '44',
  'IM': '44',
  'GP': '590',
  'GG': '44',
  'GF': '594',
  'FO': '298',
  'FK': '500',
  'EH': '212',
  'CX': '61',
  'CW': '599',
  'CC': '61',
  'BQ': '599',
  'BL': '590',
  'AX': '358'
};

export interface IAppCountry {
  iso: string;
  nameTranslations: ISimpleTranslation[];
  searchClassPrices: IApplicationClassPrice[];
  applicationClassPrices: IApplicationClassPrice[];
}

export type InternalApiDataName =
  | 'common-translations'
  | 'contact-methods'
  | 'cookies-pop-up'
  | 'country-names'
  | 'currencies'
  | 'entities-status'
  | 'languages'
  | 'main-footer'
  | 'main-nav-bar';
