import { COUNTRY_CODES, CountryCode, countryCallingCodes } from '../models/app.models';
import { IBlogPostsResult, IClass, ICountry, ICurrency, IMedia, IMetaComponent, IOrder, IOrderItemClass, IStrapiCollectionResult, ITrademarkLanding, OrderStatus, ServiceType, TrackerStatus } from '../models/strapi.models';
import { fetchStrapi } from '../services/api.service';
import { logError } from '../services/log.service';
import { AppOrder, IAppOrder, IAppOrderClass } from '../store/models/store.models';
import { IAppPage, IAppPageLocalization } from '../store/slices/appSlice';

export const addCurrencySymbol = (value: number | undefined, currencyCode: string, locale: string) => {
  let currencySymbol = 'US$';

  if (currencyCode === 'EUR') {
    currencySymbol = '€';
  }

  if (currencyCode === 'GBP') {
    currencySymbol = '£';
  }

  if (locale === 'en') {
    return `${currencySymbol} ${value || '-'}`
  } else {
    return `${value || '-'} ${currencySymbol}`
  }
}

export const applyCurrencyConversionRate = (value: number, conversionRate: number | undefined) => {
  const convertedValue = value * (conversionRate || 1);
  return Math.ceil(convertedValue / 5) * 5;
}

export const convertValueToUSD = (value: number, sourceCurrency: ICurrency | undefined) => {
  if (!sourceCurrency || sourceCurrency.isoCode === 'USD') {
    return value;
  }

  return Math.ceil((value / sourceCurrency.conversionRate!) / 5) * 5;
}


export const formatDate = (date: string | Date = new Date()) => {
  const newDate = new Date(date);
  const day = String(newDate.getDate()).padStart(2, '0');
  const month = String(newDate.getMonth() + 1).padStart(2, '0'); // Los meses en JavaScript empiezan en 0
  const year = newDate.getFullYear();

  return `${day}/${month}/${year}`;
}

export const addDays = (date: Date, days: number) => {
  const newDate = new Date(Number(date));
  newDate.setDate(date.getDate() + days);
  return newDate;
}

export const flatStrapiCollection = <T>(result: IStrapiCollectionResult<T>): T[] => {
  const flattened: T[] = [];
  result.data.forEach((x) => {
    flattened.push({
      id: x.id,
      ...x.attributes,
    });
  });

  return flattened as T[];
}

export const flatStrapiObject = <T>(result: any): T => {
  let obj: any = {
    id: result.data.id,
    ...result.data.attributes,
  };
  return obj as T;
}

export const getTranslation = <T>(
  componentCollection: T[] | undefined | null,
  locale: string
): T => {
  if (!componentCollection) {
    return { text: 'n/a' } as T;
  }

  try {
    const translation = componentCollection.find(x => (x as any)['language'] === locale) as T;
    const fallbackTranslation = componentCollection.find(x => (x as any)['language'] === 'en' || (x as any)['language'] === 'es' || (x as any)['language'] === 'fr') as T;
    return translation || fallbackTranslation;
  } catch (error) {
    return { text: 'n/a' } as T;
  }
}

export const randomNumber = (min: number, max: number): number => {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export const getAppPages = async (countries: ICountry[]): Promise<IAppPage[]> => {
  const rewrites: IAppPage[] = [];
  const appPages = JSON.parse(process.env.NEXT_PUBLIC_APP_PAGES as string);

  for (const page of appPages) {
    const urlParams = {
      'populate[0]': 'meta',
    };

    const response = await fetchStrapi<any>(`/${page.apiName}`, urlParams);
    const pageMetas = response.data.attributes.meta;

    // Iterate metas (translations)
    pageMetas.forEach((x: any) => {
      if (x.language !== 'en') {
        rewrites.push({
          source: `/${x.language}/${x.slug}`,
          destination: `/${page.appPath}`,
          locale: false,
          language: x.language,
        });
      } else {
        rewrites.push({
          source: `/${x.slug}`,
          destination: `/${page.appPath}`,
          language: x.language
        });
      }
    });

    // Country landings
    if (page.apiName === 'trademark-registration-order-page') {
      pageMetas.forEach((x: any) => {
        countries.forEach(c => {
          const countryNameTranslation = c.nameTranslations.find(t => t.language === x.language);
          const countrySlug = countryNameTranslation!.text!
            .toLowerCase()
            .replace(/ /g, '-')
            .replace(/[^\w-]+/g, '');

          const rewrite = {
            destination: `/countries/${c.iso}`,
            source: `/${x.slug}-${countrySlug}`,
            locale: false,
            language: x.language,
          }
          if (x.language !== 'en') {
            rewrite.destination = `/${x.language}/countries/${c.iso}`;
            rewrite.source = `/${x.language}/${x.slug}-${countrySlug}`;
          }

          rewrites.push(rewrite);
        });
      });
    }
  }

  // Blog posts
  for (const appLocale of ['en', 'es', 'fr']) {
    const blogPostsUrlParams = new URLSearchParams({
      locale: appLocale,
      'pagination[start]': '0',
      'pagination[limit]': '-1',
    });
    const blogPostsResponse: IBlogPostsResult =
      await fetchStrapi<IBlogPostsResult>(
        `/blog-posts/published`,
        blogPostsUrlParams
      );

    for (const post of blogPostsResponse.results) {
      const rewrite: any = {
        destination: `/blog/${post.id}`,
        language: appLocale,
        localizations: post.localizations?.map(lo => ({
          id: lo.id,
          locale: lo.locale
        }))
      };

      if (appLocale !== 'en') {
        rewrite.destination = `/${appLocale}/blog/${post.id}`;
        rewrite.source = `/${appLocale}/${post.slug}`;
      } else {
        rewrite.source = `/${post.slug}`;
      }

      rewrites.push(rewrite);
    }
  }

  return rewrites;
}

export const downloadFile = async (url: string, fileName: string): Promise<void> => {
  try {
    const response = await fetch(url);
    if (response.status !== 200) {
      return;
    }

    const blob = await response.blob();
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = fileName;
    document.body.appendChild(downloadLink);
    downloadLink.click();

    setTimeout(() => {
      URL.revokeObjectURL(downloadLink.href);
      document.body.removeChild(downloadLink);
    }, 100);
  } catch (error) {
    logError([error], 'Utils.downloadFile');
  }
}

export const getOrderStatusPropertyName = (status: OrderStatus): string => {
  if (status === OrderStatus.CANCELED) {
    return 'orderCanceled';
  }

  if (status === OrderStatus.COMPLETED) {
    return 'orderCompleted';
  }

  if (status === OrderStatus.DRAFT) {
    return 'orderDraft';
  }

  if (status === OrderStatus.PENDING) {
    return 'orderPending';
  }

  if (status === OrderStatus.PAID_BUTTON_ERROR_CREATING_TRACKERS) {
    return 'orderButErrorCreatingTrackers';
  }

  if (status === OrderStatus.PAID_AND_PENDING_TRACKER_CREATION) {
    return 'orderPaidAndPendingTrackersCreation';
  }

  return '';
}

export const getPaymentStatusPropertyName = (status: 'completed' | 'pending'): string => {
  if (status === 'pending') {
    return 'paymentPending';
  }

  if (status === 'completed') {
    return 'paymentDone';
  }

  return '';
}

export const getServiceTrackerOrderStatusPropertyName = (status: TrackerStatus): string => {
  if (status === TrackerStatus.IN_PROCESS) {
    return 'trackerInProcess';
  }

  if (status === TrackerStatus.REGISTERED) {
    return 'trackerRegistered';
  }

  if (status === TrackerStatus.FILED) {
    return 'trackerFiled';
  }

  if (status === TrackerStatus.COMPLETED) {
    return 'trackerCompleted';
  }

  if (status === TrackerStatus.INACTIVE) {
    return 'trackerInactive';
  }

  if (status === TrackerStatus.ABANDONED) {
    return 'trackerAbandoned';
  }

  if (status === TrackerStatus.REFUSED) {
    return 'trackerRefused';
  }

  if (status === TrackerStatus.WAITIN_FOR_POA) {
    return 'trackerWaitingForPoa';
  }
  return '';
}

export const mapAppOrder = (order: IOrder, userId: number, locale?: string): IAppOrder => {
  const appOrder: IAppOrder = new AppOrder(ServiceType.SEARCH); // Set initial default order
  appOrder.userId = userId || 0;
  appOrder.strapiOrderId = order.id;
  appOrder.currency = order.currency as ICurrency;
  appOrder.totalPrice = order.totalPrice || 0;

  if (order.orderItems) {
    appOrder.type = order.orderItems[0]?.serviceType;
    appOrder.trademarkName = order.orderItems[0]?.trademarkText || '-';

    if (order.orderItems[0]?.classes) {
      appOrder.trademarkClasses = (
        order.orderItems[0]?.classes as IOrderItemClass[]
      ).map((x: IOrderItemClass) => ({
        id: x.class.id as number,
        code: (x.class as IClass).code,
        name: getTranslation(
          (x.class as IClass).descriptionTranslations,
          locale || 'en'
        ).text as string,
        description: x.description,
      }));
    }

    if (order.orderItems[0]?.countries) {
      appOrder.trademarkCountries = (
        order.orderItems[0]?.countries as ICountry[]
      ).map((x: ICountry) => ({
        name: getTranslation(
          x.nameTranslations,
          locale || 'en'
        ).text as string,
        code: x.iso,
        dividesByClass: x.dividesByClass,
        id: x.id as number,
        applicationClassPrices: x.applicationClassPrices.map(f => {
          f.valueName = f.valueName || '';
          return f;
        }),
        finalFees: x.finalFees.map(f => {
          f.valueName = f.valueName || '';
          return f;
        }),
        searchClassPrices: x.searchClassPrices.map(f => {
          f.valueName = f.valueName || '';
          return f;
        }),
      }));
    }

    if (order.orderItems[0]?.logo) {
      const thumbnailUrl = (order.orderItems[0].logo as IMedia)?.formats?.thumbnail?.url;
      if (!!thumbnailUrl) {
        appOrder.trademarkLogo = thumbnailUrl;
      }
    }
  }

  return appOrder;
}

export const getFlagPath = (countryCode: string) => {
  return `/assets/images/common/flags/${countryCode.toLowerCase()}.svg`
}

export const textToSlug = (text: string) => {
  return text
    .toLowerCase() // Convertir todo a minúsculas
    .normalize("NFD") // Normalizar caracteres acentuados
    .replace(/[\u0300-\u036f]/g, "") // Eliminar caracteres diacríticos
    .replace(/[^\w-]+/g, '-') // Reemplazar caracteres especiales con guiones
    .replace(/--+/g, '-') // Reemplazar múltiples guiones con uno solo
    .replace(/^-+|-+$/g, ''); // Eliminar guiones al principio y al final
}

export const getCanonicalLink = (locale: string, slug: string | null) => {
  const host = process.env.NEXT_PUBLIC_WEBSITE_URL as string;
  if (slug) {
    slug = slug.replace(/^\/+/, '');
  }

  if (locale === 'en') {
    return !!slug ? `${host}/${slug}` : `${host}`;
  } else {
    return !!slug ? `${host}/${locale}/${slug}` : `${host}/${locale}`;
  }
};

export const getContentLanguageCode = (locale: string) => {
  if (locale === 'es') {
    return 'es-es';
  }
  if (locale === 'es') {
    return 'fr-fr';
  }

  return 'en-us';
};

export const getAlternateLinks = (path: string, appPages?: IAppPage[]) => {
  const host = process.env.NEXT_PUBLIC_WEBSITE_URL as string;
  const locales = JSON.parse(process.env.NEXT_PUBLIC_APP_LOCALES || '[]') as string[];
  const alternateLinks: { href: string; hrefLang: string }[] = [];

  if (!path || path === '/') {
    locales?.forEach(x => {
      const href = x === 'en' ? `${host}` : `${host}/${x}`;
      alternateLinks.push({ href, hrefLang: x });

      if (x === 'en') {
        alternateLinks.push({ href: `${href}`, hrefLang: 'x-default' });
      }
    });
  } else {
    appPages?.filter(x => x.original === path).forEach(x => {
      alternateLinks.push({ href: `${host}${x.source}`, hrefLang: x.language });

      if (x.language === 'en') {
        alternateLinks.push({ href: `${host}${x.source}`, hrefLang: 'x-default' });
      }
    });
  }

  return alternateLinks;
};

export const getAlternateLinksForBlogPost = (locale: string, slug: string, appPages?: IAppPage[]) => {
  const host = process.env.NEXT_PUBLIC_WEBSITE_URL as string;
  const alternateLinks: { href: string; hrefLang: string }[] = [];

  if (locale !== 'en') {
    let localizations: IAppPageLocalization[] = [];
    appPages?.filter(x => !!x.localizations?.find(l => l.id === parseInt(slug))).forEach(p => {
      if (p.localizations) {
        p.localizations.forEach(localization => {
          if (!localizations.some(l => l.id === localization.id)) {
            localizations.push(localization);
          }
        });
      }
    });

    localizations.forEach(l => {
      appPages?.filter(x => x.original === `/blog/${l.id}`).forEach(p => {
        if (p.language === 'en') {
          alternateLinks.push({ href: `${host}${p.source}`, hrefLang: 'x-default' });
        }

        alternateLinks.push({ href: `${host}${p.source}`, hrefLang: p.language });
      });
    });
  } else {
    appPages?.filter(x => x.original === `/blog/${slug}`).forEach(p => {
      if (p.language === 'en') {
        alternateLinks.push({ href: `${host}${p.source}`, hrefLang: 'x-default' });
      }

      alternateLinks.push({ href: `${host}${p.source}`, hrefLang: p.language });
    });
  }

  return alternateLinks;
};

export const getAlternateLinksForCountry = (locale: string, slug: string, appPages?: IAppPage[]) => {
  const host = process.env.NEXT_PUBLIC_WEBSITE_URL as string;
  const alternateLinks: { href: string; hrefLang: string }[] = [];
  const originalPath = appPages?.find((x) => x.destination.includes(slug))?.original || undefined;

  appPages?.filter((x) => x.original === originalPath && x.language !== locale).forEach((p) => {
    alternateLinks.push({ href: `${host}${p.source}`, hrefLang: p.language });
  });

  return alternateLinks;
};

export const getImageSrcWithFallback = (media: IMedia | null | undefined, format: 'medium' | 'large'): string => {
  if (!!media) {

    if (format === 'medium' && !!media.formats && !!media.formats.medium) {
      return media.formats.medium.url as string;
    }

    if (format === 'large' && !!media.formats && !!media.formats.large) {
      return media.formats.large.url as string;
    }

    if (!!media.formats && !media.formats.large && !media.formats.medium && !!media.formats.small) {
      return media.formats.small.url as string;
    }

    return media.url as string;
  }

  return '/assets/images/common/trademark-logo-fallback.svg';
}

export const getThumbnailSrcWithFallback = (media: IMedia): string => {
  if (!!media) {

    if (!!media.formats) {
      if (!!media.formats.thumbnail) {
        return media.formats.thumbnail.url as string;
      }
      if (!!media.formats.small) {
        return media.formats.small.url as string;
      }
    } else {
      return media.url as string;
    }

  }

  return '/assets/images/common/trademark-logo-fallback.svg';
}

export const getLocalizedSlugByCountryIso = (landings: ITrademarkLanding[] | {
  country: { iso: string };
  metas: IMetaComponent[];
}[], iso: string, locale: string): string => {
  const landing = landings.find(
    (x) => x.country.iso === iso
  );
  const landingMeta = getTranslation(
    landing?.metas,
    locale
  );

  return landingMeta?.slug || iso;
}

export const getClassByCode = (classes: IAppOrderClass[], classCode: number) => {
  return classes.find((x) => x.code === classCode);
};

export const htmlToText = (html: string) => {
  if (typeof document !== 'undefined') {
    var temp = document.createElement('DIV');
    temp.innerHTML = html;
    return temp.textContent || temp.innerText || '';
  } else {
    return '';
  }
};

export const generateRandomId = () => {
  return Math.random().toString(36).slice(2, 12);
}

export const isValidPhoneNumber = (phoneNumber: string) => {
  const regex = /^\+?\d{7,15}$/;
  return regex.test(phoneNumber);
};

export const getCountryCodes = () => {
  return COUNTRY_CODES;
};

export const getCountryCallingCode = (countryCode: keyof typeof countryCallingCodes | CountryCode) => {
  return countryCallingCodes[countryCode as keyof typeof countryCallingCodes];
};

export const decomposePhoneNumber = (fullPhoneNumber: string): {
  dialCode: string;
  phoneNumber: string;
  countryCode: CountryCode | null;
} => {
  // Elimina cualquier carácter no numérico
  const numericPhoneNumber = fullPhoneNumber.replace(/\D/g, '');

  // Busca el código de marcación en el número de teléfono
  for (const countryCode of COUNTRY_CODES) {
    const dialCode = getCountryCallingCode(countryCode as keyof typeof countryCallingCodes);
    if (numericPhoneNumber.startsWith(dialCode)) {
      return {
        dialCode: `+${dialCode}`,
        phoneNumber: numericPhoneNumber.substring(dialCode.length),
        countryCode: countryCode as CountryCode,
      };
    }
  }

  // Si no se encuentra ningún código de marcación, devuelve el número de teléfono tal cual
  return {
    dialCode: '',
    phoneNumber: numericPhoneNumber,
    countryCode: null,
  };
};

export const removeObjectProperties = <T>(obj: T, propsToRemove: string[]) => {
  for (let prop in obj) {
    if (typeof obj[prop] === 'object' && obj[prop] !== null) {
      removeObjectProperties(obj[prop], propsToRemove);
    }
    if (propsToRemove.includes(prop)) {
      delete obj[prop];
    }
  }
  return obj;
}

export const keepObjectProperties = <T>(obj: any, propsToKeep: string[]): T => {
  let filteredObj: any = {};
  for (let prop in obj) {
    if (propsToKeep.includes(prop)) {
      filteredObj[prop] = (typeof obj[prop] === 'object' && obj[prop] !== null) ? keepObjectProperties(obj[prop], propsToKeep) : obj[prop];
    }
  }
  return filteredObj;
}

export const calculateStripeAmount = (amount: number, currency?: ICurrency) => {
  let stripeAmount = amount * 100;

  if (currency?.numberOfDecimals === 0) {
    stripeAmount = amount;
  }

  return stripeAmount;
}