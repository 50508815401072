import { IAddress, IApplicationClassPrice, ICountry, ICountryPrice, ICurrency, IMedia, IOrder, IOrderItem, IPayment, ServiceType } from '../models/strapi.models';
import { deleteStrapi, fetchStrapi, postStrapi, putStrapi, uploadStrapi } from '../services/api.service';
import { logError } from '../services/log.service';
import { IAppOrder, IAppOrderCountry } from '../store/models/store.models';
import { applyCurrencyConversionRate, flatStrapiObject, generateRandomId } from './useUtils';

export const getOrderById = async (orderId: number) => {
  try {
    const orders = await fetchStrapi<IOrder[]>('/orders/mine');
    return orders.find((x) => x.id === orderId);
  } catch (error) {
    return undefined;
  }
};

export const createCheckoutOrder = async (order: IOrder, appOrder: IAppOrder, pageServiceType: ServiceType, ownerAddress?: IAddress) => {
  const payload = {
    data: { ...order },
  };

  // Create order
  const newOrder: IOrder = flatStrapiObject(await postStrapi('/orders', payload));
  const newAppOrder = { ...appOrder };
  const orderItem: IOrderItem = {
    order: newOrder.id,
    serviceType: pageServiceType,
    trademarkText: newAppOrder.trademarkName,
    ownerAddress: ownerAddress?.address || undefined,
    ownerCity: ownerAddress?.city || undefined,
    ownerCountry: (ownerAddress?.country as ICountry)?.id || undefined,
    ownerPostCode: ownerAddress?.postCode || undefined,
    ownerName: ownerAddress?.name || undefined,
    ownerAddressAlias: ownerAddress?.alias || undefined,
    countries: newAppOrder.trademarkCountries.map((x) => x.id),
    classes: newAppOrder.trademarkClasses.map((x) => ({
      class: {
        id: x.id,
      },
      description: x.description,
    })),
    prices: newAppOrder.prices,
  };

  const orderItemPayload = {
    data: { ...orderItem },
  };

  const createdOrderItem = await postStrapi('/order-items', orderItemPayload);

  // Upload order item file
  if (newAppOrder.trademarkLogo && newAppOrder.trademarkLogo.length > 0) {
    const orderItemFileFetch = await fetch(newAppOrder.trademarkLogo);
    const orderItemFile: Blob = await orderItemFileFetch.blob();
    const form: FormData = new FormData();
    form.append('files', orderItemFile);
    await uploadStrapi(`/order-items/${createdOrderItem.data.id}/uploadLogo`, form);
  }

  return newOrder;
};

export const updateCheckoutOrder = async (orderId: number, order: IOrder, appOrder: IAppOrder, pageServiceType: ServiceType, ownerAddress?: IAddress) => {
  const payload = {
    data: { ...order },
  };

  await putStrapi(`/orders/${orderId}`, payload); // Update order
  const remoteOrder = (await getOrderById(orderId)) as IOrder; // Update order items
  const updatedAppOrder = { ...appOrder };

  if (remoteOrder.orderItems) {
    const orderItem: IOrderItem = {
      trademarkText: updatedAppOrder.trademarkName,
      ownerAddress: ownerAddress?.address || undefined,
      ownerCity: ownerAddress?.city || undefined,
      ownerCountry: (ownerAddress?.country as ICountry)?.id || undefined,
      ownerPostCode: ownerAddress?.postCode || undefined,
      ownerName: ownerAddress?.name || undefined,
      ownerAddressAlias: ownerAddress?.alias || undefined,
      countries: updatedAppOrder.trademarkCountries.map((x) => x.id),
      classes: updatedAppOrder.trademarkClasses.map((x) => ({
        class: {
          id: x.id,
        },
        description: x.description,
      })),
      prices: updatedAppOrder.prices,
    };

    // Remove existing file
    if (remoteOrder.orderItems[0].logo) {
      await deleteStrapi('/upload/files/' + (remoteOrder.orderItems[0].logo as IMedia).id);
    }

    const orderItemPayload = {
      data: { ...orderItem },
    };

    const createdOrderItem = await putStrapi(`/order-items/${remoteOrder.orderItems[0].id}`, orderItemPayload);

    // Upload order item file
    if (updatedAppOrder.trademarkLogo && updatedAppOrder.trademarkLogo.length > 0) {
      const orderItemFileFetch = await fetch(updatedAppOrder.trademarkLogo);
      const orderItemFile: Blob = await orderItemFileFetch.blob();
      const form: FormData = new FormData();
      form.append('files', orderItemFile);
      await uploadStrapi(`/order-items/${createdOrderItem.data.id}/uploadLogo`, form);
    } else {
      orderItem.logo = undefined;
    }
  }

  return remoteOrder;
}

export const createCheckoutBankWirePayment = async (appOrder: IAppOrder, currency: ICurrency, userId: number) => {
  try {
    const payment: IPayment = {
      orders: [appOrder.strapiOrderId as number],
      paid: appOrder.totalPrice as number,
      status: 'pending',
      currency: currency.id,
      type: 'bankwire',
      paymentInternalId: generateRandomId(),
      user: userId,
      used: appOrder.totalPrice as number,
      balance: 0
    };

    const paymentPayload = {
      data: { ...payment },
    };

    // Create payment
    await postStrapi(`/payments`, paymentPayload);

    // Update order status
    const orderPayload = {
      data: {
        status: 'pending',
      },
    };

    await putStrapi(`/orders/${appOrder.strapiOrderId}`, orderPayload);
  } catch (error) {
    logError(['Error creating bank wire payment', error], 'useCheckout.createCheckoutBankWirePayment');
  }
}

export const calculateCheckoutPrices = (appOrder: IAppOrder, conversionRate: number) => {
  let totalPrice = 0;
  let propertyName = '';
  const prices: ICountryPrice[] = [];

  if (appOrder.type !== ServiceType.SEARCH && appOrder.type !== ServiceType.APPLICATION) {
    totalPrice = applyCurrencyConversionRate(appOrder.totalPrice || 0, conversionRate);
    return { totalPrice, prices };
  }

  if (
    !!appOrder.trademarkCountries &&
    appOrder.trademarkCountries.length > 0
  ) {

    // CLASS PRICES SOURCE: Depending on the service type, the code below will use
    // one property or another as source price collection.
    if (appOrder.type === ServiceType.SEARCH) {
      propertyName = 'searchClassPrices';
    }

    if (appOrder.type === ServiceType.APPLICATION) {
      propertyName = 'applicationClassPrices';
    }

    appOrder.trademarkCountries.forEach((c: IAppOrderCountry) => {
      let countryClassesPrices: number[] = [];
      let countryPrice: ICountryPrice;
      let partialPrice: number;

      if ((c as any)[propertyName]) {

        // ADD BASE PRICE: This is should be lasta class price in the component list.
        // As Strapi sorts a repeatable component by descending creation date → last
        // position in the collection is actually the first value added so, it is the
        // "starting or base" price.
        countryClassesPrices = (c as any)[propertyName].map((scp: IApplicationClassPrice) => scp.value);

        // → This is the base price.
        const countryClassFirstPrice = countryClassesPrices[0];

        // → This is the price for subsequent classes.
        const countryClassLastPrice = countryClassesPrices[countryClassesPrices.length - 1];

        // → Fallback to 0 in case of first price has not been defined.
        partialPrice = !!countryClassFirstPrice ? countryClassFirstPrice : 0;
        totalPrice += partialPrice;

        // ADD SUBSEQUENT (IF EXISTS) CLASS PRICES: If the country has more "positions"
        // in the repeatable component, it means more amounts have to be added.
        if (!!appOrder.trademarkClasses && appOrder.trademarkClasses.length > 1) {
          for (let index = 1; index < appOrder.trademarkClasses.length; index++) {

            // Add equivalent position price or, if there are more classes than prices,
            // add the last price available.
            partialPrice += !isNaN(countryClassesPrices[index]) ? countryClassesPrices[index] : countryClassLastPrice;
            totalPrice += !isNaN(countryClassesPrices[index]) ? countryClassesPrices[index] : countryClassLastPrice;
          }
        }

        countryPrice = {
          country: c.id,
          price: partialPrice,
        };

        prices.push(countryPrice);
      }
    });
  }

  // Apply currency conversion rate only in order's total price but not in order items prices (it should be in the base currency → USD)
  totalPrice = applyCurrencyConversionRate(totalPrice, conversionRate);

  return { totalPrice, prices };
}