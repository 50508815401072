import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '..';
import { ICurrency } from '../../models/strapi.models';
import { calculateCheckoutPrices } from '../../shared/useCheckout';
import { IAppOrder } from '../models/store.models';

const initialState: IAppOrder = {
  id: '',
  type: undefined,
  trademarkCountries: [],
  trademarkName: '',
  trademarkLogo: '',
  trademarkClasses: [],
  addressId: undefined,
  billingAddressId: undefined,
  userId: undefined,
  strapiOrderId: undefined,
  totalPrice: undefined,
  prices: [],
  currency: undefined,
};

export const currentOrderSlice = createSlice({
  name: 'currentOrder',
  initialState,
  reducers: {
    setCurrentOrder: (state, action: PayloadAction<IAppOrder>) => {
      const trademarkCountries = action.payload.trademarkCountries || [];
      const trademarkClasses = action.payload.trademarkClasses || [];
      const { totalPrice, prices } = calculateCheckoutPrices(action.payload, action.payload.currency?.conversionRate || 1);

      state.id = action.payload.id;
      state.type = action.payload.type;
      state.trademarkCountries = trademarkCountries;
      state.trademarkName = action.payload.trademarkName;
      state.trademarkLogo = action.payload.trademarkLogo;
      state.trademarkClasses = trademarkClasses;
      state.addressId = action.payload.addressId;
      state.billingAddressId = action.payload.billingAddressId;
      state.userId = action.payload.userId;
      state.strapiOrderId = action.payload.strapiOrderId;
      state.totalPrice = totalPrice;
      state.prices = prices;
      state.currency = action.payload.currency;
    },
    resetCurrentOrder: (state) => {
      state.id = '';
      state.type = undefined;
      state.trademarkCountries = [];
      state.trademarkName = '';
      state.trademarkLogo = '';
      state.trademarkClasses = [];
      state.addressId = undefined;
      state.billingAddressId = undefined;
      state.userId = undefined;
      state.strapiOrderId = undefined;
      state.totalPrice = undefined;
      state.prices = [];
      state.currency = undefined;
    },
    recalculatePrices: (state, action: PayloadAction<ICurrency>) => {
      const { totalPrice, prices } = calculateCheckoutPrices(state, action.payload.conversionRate || 1);
      state.totalPrice = totalPrice;
      state.prices = prices;
    },
  },
});

export const { setCurrentOrder, resetCurrentOrder, recalculatePrices } = currentOrderSlice.actions;
export const getCurrentOrder = (state: RootState) => state.currentOrder;
export default currentOrderSlice.reducer;
